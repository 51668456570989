<template>
  <v-container fluid>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>Route User Assignments</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small text @click="goBack">
        <v-icon left dark>mdi-skip-backward</v-icon>Back
      </v-btn>
    </v-toolbar>
    <!-- editor -->
    <editor :initial="itemsData" @data="save"></editor>

    <!-- end -->
  </v-container>
</template>

<script>
import Editor from "../components/create-editor.vue";
export default {
  components: {
    Editor,
  },
  data: () => ({
    itemsData: [],
    editMode: false,
    Dialog: false,
    DocApproval: {},
    docData: {},
  }),
  methods: {
    save(data) {
      this.docData = data;
      let URL = "/route_assignment/approval_templates";
      if (this.docData.addDraft == true) {
        URL = "/route_assignment";
      }
      console.log(this.docData);

      const self = this;
      this.$store
        .dispatch("post", { URL, data })
        .then(() => {
          this.Dialog = true;
          self.$store.commit("loader", false);
          // self.$refs.snackbar.show("Item group created", "green");

          // self.$router.push("/routes-assignment");
        })
        .catch((err) => {
          console.log(err);
          self.$store.commit("loader", false);
          self.$refs.snackbar.show(err, "red");
        });
    },
    DocSave() {
      // const url = "/route_assignments";
      // const self = this;
      // this.docData.DocApproval = this.DocApproval;
      // const DATA = this.docData;
      // this.$store
      //   .dispatch("post", { url, DATA })
      //   .then(() => {
      //     self.$store.commit("loader", false);
      //     // self.$refs.snackbar.show("Item group created", "green");
      //     self.$router.push("/routes-assignment");
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     self.$store.commit("loader", false);
      //     // self.$refs.snackbar.show(err, "red");
      //   });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  created() {},
};
</script>
